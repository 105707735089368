<template>
  <div style="padding-bottom: 100px;">
    <h4 class="mb-2">สรุปรายงานการทำธุกรรมแต่ละช่องทาง</h4>

    <b-card no-body>
      <v-select v-model="dayQuery" :options="dayQueryOptions" :clearable="false" @input="fetchData" />
    </b-card>

    <div v-if="!loading && errorMessage">
      <p class="text-center text-danger">{{ errorMessage }}</p>
    </div>

    <b-overlay :show="loading" spinner-small>
      <b-row cols="1" cols-md="2">
        <b-col v-for="provider in formatListData" :key="provider.provider">
          <b-card>
            <div class="d-flex align-items-center mb-1">
              <b-img :src="provider.iconPath" alt="browser img" class="mr-1" height="30" />

              <h5 class="mb-0 text-uppercase">
                {{ provider.provider }} <small>({{ provider.details.length }} หมวดธุรกรรม)</small>
              </h5>
            </div>
            <div v-for="(details, idx) in provider.details" :key="`${provider.provider}-transaction-${idx}`">
              <div class="d-flex align-item-center justify-content-between mt-1" style="gap: 1rem;">
                <p class="mb-0">{{ details.name }} ({{ gFormatNumberToCurrency(details.count_provider) }} ครั้ง)</p>
                <p v-if="details.show_amount" class="mb-0">{{ gFormatNumberToCurrency(details.amount) }} บาท</p>
              </div>

              <div
                v-for="subDetail in details.sub_details"
                :key="subDetail.id"
                class="d-flex align-item-center justify-content-between mt-50 ml-1"
                style="gap: 1rem;"
                :class="renderClassFromObj(subDetail)"
              >
                <p class="mb-0">- {{ subDetail.name }} ({{ gFormatNumberToCurrency(subDetail.count) }} ครั้ง)</p>
                <p class="mb-0">{{ gFormatNumberToCurrency(subDetail.amount) }} บาท</p>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      listData: [],
      providersIcon: {
        // eslint-disable-next-line global-require
        apple: require('@/assets/images/icons/socials/apple.svg'),
        // eslint-disable-next-line global-require
        facebook: require('@/assets/images/icons/socials/fb.svg'),
        // eslint-disable-next-line global-require
        phone: require('@/assets/images/icons/socials/phone.svg'),
        // eslint-disable-next-line global-require
        line: require('@/assets/images/icons/socials/line.svg'),
        // eslint-disable-next-line global-require
        google: require('@/assets/images/icons/socials/google.svg'),
        // eslint-disable-next-line global-require
        another: require('@/assets/images/icons/socials/another.svg'),
      },
      providersColor: {
        apple: '#a6b1b7',
        facebook: '#3b5998',
        phone: '#4a154b',
        line: '#25d366',
        google: '#f35022',
        another: '#282828',
      },
      loading: false,
      dayQueryOptions: [
        {
          label: 'วันนี้',
          value: '1',
        },
        {
          label: 'เมื่อวานนี้',
          value: '2',
        },
        {
          label: '7 วันย้อนหลัง',
          value: '7',
        },
        {
          label: '15 วันย้อนหลัง',
          value: '15',
        },
        {
          label: '30 วันย้อนหลัง',
          value: '30',
        },
        {
          label: 'รวมทุกช่วงเวลา',
          value: 'all',
        },
      ],
      dayQuery: {
        label: 'วันนี้',
        value: '1',
      },
      errorMessage: '',
    }
  },
  computed: {
    formatListData() {
      return this.listData.map(item => {
        const color = this.providersColor[item.provider] ?? '#5d5fef'
        const iconPath = this.providersIcon[item.provider] ?? ''

        return {
          ...item,
          color,
          iconPath,
        }
      })
    },
    renderClassFromObj() {
      return objTarget => objTarget?.class ?? ''
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.errorMessage = ''
      this.loading = true
      const resp = await this.api
        .getV2(`api/admin/dashboard/report-ref-provider/${this.dayQuery.value}`)
        .catch(() => null)
      this.loading = false

      if (resp?.code === 200) {
        this.listData = [...resp.data]
      } else {
        this.listData = []
        this.errorMessage = 'พบข้อผิดพลาดในการดึงข้อมูล โปรดติดต่อผู้ดูแลระบบ'
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
